@import '../../styles/index';

.annimation {
  margin: 0;
  margin-bottom: 0 !important;

  @include media-from(sm) {
    margin: 3rem 0 0;
  }
}

.headline {
  margin-top: -1.5rem;
  margin-bottom: 0 !important;

  @include media-from(sm) {
    margin-top: -2rem;
  }
}

.richtext {
  font-weight: $font-weight-regular !important;
  margin-bottom: 1.5rem !important;
  max-width: 600px;

  @include media-from(sm) {
    margin-bottom: 2.5rem !important;
  }
}

.button {
  padding: 0 2rem;
}
